import React from 'react'
import { AboutUs } from '../components'

const AboutPage = () => {
  return (
    <div>
        <AboutUs />  
    </div>
  )
}

export default AboutPage
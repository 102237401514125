import React from 'react'
import { AllProjects } from '../components'

const ProjectsPage = () => {
  return (
    <div>
        <AllProjects />
    </div>
  )
}

export default ProjectsPage